import { createSphereApi } from '@spherelabs/api-client';
import { SphereApiVersion } from '@spherelabs/common';

import { useUser } from '@/common/hooks/useUser';

export function useSphereApi(apiVersion?: SphereApiVersion) {
  const { token } = useUser();
  return createSphereApi({ token: token ?? '', apiVersion });
}

export type SphereApi = ReturnType<typeof useSphereApi>;
export type SphereApiEndpoints = keyof SphereApi;
