import { type NormalizeOAS, createClient } from 'fets';

import { SphereApiVersion } from '@spherelabs/common';
import type openapi from './openapi';

export {
  type OASModel as Model,
  type OASRequestParams as RequestParams,
  type OASOutput as Response,
} from 'fets';

type NormalizedOAS = NormalizeOAS<typeof openapi>;

const endpoint = process.env.NEXT_PUBLIC_API_URL ?? 'http://localhost:8080';

export function createSphereApi({
  token,
  apiVersion,
}: { token: string; apiVersion?: SphereApiVersion }) {
  return createClient<NormalizedOAS>({
    // @ts-expect-error
    endpoint,
    globalParams: {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(apiVersion ? { version: apiVersion } : null),
      },
    },
  });
}

export type { NormalizedOAS as SphereApiSchema };
