import {
  ApiKey,
  Application,
  ApplicationStatistics,
  AstraFiCard,
  BankAccountV2,
  CustomerV2,
  DEFAULT_EVENTS_PER_QUERY,
  Event,
  EventV2,
  Network,
  Payment,
  PayoutOnOffRampProvider,
  Request,
  SphereApiVersion,
  Subscription,
  Wallet,
  Webhook,
  WebhookRecord,
  isEvm,
} from '@spherelabs/common';

import { RequestParams, SphereApiSchema } from '@spherelabs/api-client';

import type {
  AccessRequestCustomerType,
  AccessRequestStatus,
  FetchQuotesParams,
  JupiterRoute,
  PaymentLink,
  PayoutCurrency,
  PayoutNetwork,
  Product,
  SphereRamp,
  Token,
  TokensRouteMap,
} from '@spherelabs/common';
import { PayoutAmount } from '@spherelabs/common';
import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import useSWRMutation from 'swr/mutation';

import { useUser } from '@/common/hooks/useUser';
import { useCustomerToken } from '@/features/customer-portal/providers/CustomerContext';

import { CustomerForFrontend } from '@/features/customer-detail/types/customer';
import { PayoutStatsOvertimeV2 } from '@spherelabs/common/src/types/resourcesV2';
import {
  CreateAccessRequestDTO,
  GetPayoutAmountAdjustmentsDTO,
  SphereApiResponseDTO,
} from '@spherelabs/dtos';
import { useSearchParams } from 'next/navigation';
import type { SetNonNullable } from 'type-fest';
import useDebounce from './useDebounce';
import { SphereApiEndpoints, useSphereApi } from './useSphereApi';

// Membership
export const useMemberships = (isUsingAppDirectory = false) => {
  const { token } = useUser();
  const swr = useSWR(
    !token ? null : ['memberships', token],
    async ([_, token]) =>
      axios
        .get<{ data: any }>('/v1/membership', {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data),
  );
  return swr;
};

export const useSubscription = (subscriptionId: string) => {
  const { token } = useUser();
  return useSWR(
    !token ? null : 'subscriptionDetails',
    async () => {
      const url = `/v1/subscription/${subscriptionId}`;
      const { data } = await axios.get<{
        data: { subscription: Subscription };
      }>(url, {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      });
      return data;
    },
    {
      refreshInterval: 2500, // TODO: remove this once the components are refactored
    },
  );
};

export interface CustomerUpdateSubscriptionArgs {
  account: string;
  approvedAmount: number;
}

/**
 * @deprecated method is included in the `CustomerSubscriptionHeader` component directly
 */
export const useCustomerUpdateSubscription = (
  subscriptionId: string | undefined,
) => {
  const token = useCustomerToken();
  const swr = useSWRMutation(
    !token || subscriptionId === undefined ? null : [subscriptionId, token],
    (
      [_, token],
      {
        arg: { account, approvedAmount },
      }: {
        arg: CustomerUpdateSubscriptionArgs;
      },
    ) =>
      axios.post(
        `/v1/public/subscription/${subscriptionId}?${qs.stringify({
          approvedAmount,
        })}`,
        {
          account,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        },
      ),
    {
      optimisticData: ((data: any) => {
        return data;
      }) as any,
      revalidate: false,
    },
  );
  return swr;
};

export interface UpdateSubscriptionArgs {
  status: 'canceled';
}

export const useUpdateSubscription = (subscriptionId: string | undefined) => {
  const { token } = useUser();
  const swr = useSWRMutation(
    !token || subscriptionId === undefined ? null : [subscriptionId, token],
    (
      [_, token],
      {
        arg: { status },
      }: {
        arg: UpdateSubscriptionArgs;
      },
    ) =>
      axios.post(
        `/v1/subscription/${subscriptionId}`,
        {
          status,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        },
      ),
    {
      optimisticData: ((currentData: Subscription) => {
        return currentData;
      }) as any,
      revalidate: false,
    },
  );

  return swr;
};

export const useCustomer = (customerId: string | undefined, mock = false) => {
  const { token } = useUser();
  const swr = useSWR(
    !token || !customerId ? null : ['customer', customerId, token, mock],
    async ([_, customerId, token, mock]) =>
      axios
        .get<{ data: { customer: CustomerForFrontend } }>(
          `/v1/customer/frontend/${customerId}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              version: SphereApiVersion.ONE,
            },
            params: {
              mock,
            },
          },
        )
        .then((response) => response.data.data.customer),
  );
  return swr;
};

export const useApplicationCustomer = (mock = false) => {
  const { token } = useUser();
  const swr = useSWR(
    !token ? null : ['/v1/customer/frontend/application', token, mock],
    async ([path, token, mock]) =>
      baseAxios
        .post<{ data: { customer: CustomerV2 } }>(
          path,
          {},
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
            params: {
              mock,
            },
          },
        )
        .then((response) => response.data.data.customer),
  );

  return swr;
};

export const useApplicationCustomerAstraFiInfo = (mock = false) => {
  const { token } = useUser();
  const swr = useSWR(
    !token
      ? null
      : ['astraFiInfo', '/v1/customer/frontend/application', token, mock],
    async ([_, path, token, mock]) =>
      baseAxios
        .post<{
          data: {
            hasAuthorizedAstraFi: boolean;
            astraFiUserIntentId: string | null;
          };
        }>(path, undefined, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          params: {
            mock,
          },
        })
        .then((response) => response.data.data),
  );

  return swr;
};

export const useApplicationCustomerToken = (mock = false) => {
  const { token } = useUser();
  const { data } = useApplicationCustomer(mock);
  const customerId = data?.id;

  const swr = useSWRImmutable(
    !token || !customerId
      ? null
      : [`/v1/customer/${customerId}/tosLink`, token, mock],
    async ([path, token, mock]) =>
      baseAxios
        .post<{ data: { token: string } }>(path, undefined, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          params: {
            mock,
          },
        })
        .then((response) => response.data.data.token),
    {
      refreshInterval: 1000 * 60 * 30, // refetch customer token every 30 minutes
    },
  );

  return swr;
};

export const useApplicationCustomerTosStatus = (mock = false) => {
  const { data: customerToken } = useApplicationCustomerToken(mock);
  const { data } = useApplicationCustomer(mock);
  const customerId = data?.id;

  const swr = useSWR(
    !customerToken || !customerId
      ? null
      : ['/v1/public/customer/status', customerToken, mock],
    async ([path, customerToken, mock]) =>
      baseAxios
        .get(path, {
          headers: {
            authorization: `Bearer ${customerToken}`,
          },
          params: {
            mock,
          },
        })
        .then((response) =>
          Boolean(response?.data?.data?.customerTosStatus === 'approved'),
        ),
  );

  return swr;
};

export const useApplicationCustomerOnboardingState = () => {
  const { data: customerToken } = useApplicationCustomerToken();
  const { data } = useApplicationCustomer();
  const customerId = data?.id;

  const swr = useSWR(
    !customerToken || !customerId
      ? null
      : ['/v1/public/customer/onboarding/state', customerToken],
    async ([path, customerToken]) =>
      baseAxios
        .get<{
          data: {
            state: SphereRamp.Onboarding.State;
            hasCompletedOnboarding: boolean;
            preBankAccountOnboardingCompleted: boolean;
          };
        }>(path, {
          headers: {
            authorization: `Bearer ${customerToken}`,
          },
        })
        .then((response) => response.data.data),
  );

  return swr;
};

export const useApplicationCustomerSepaStatus = (mock = false) => {
  const { token } = useUser();
  const { data } = useApplicationCustomer(mock);
  const customerId = data?.id;

  const swr = useSWR(
    !token || !customerId
      ? null
      : [`/v1/customer/${customerId}/sepa`, token, mock],
    async ([path, token, mock]) =>
      baseAxios
        .post<{
          data: {
            tos_v2_acceptance: 'incomplete' | 'approved';
            kyc_with_proof_of_address: 'incomplete' | 'approved';
          };
        }>(path, undefined, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          params: {
            mock,
          },
        })
        .then((response) => response.data.data),
  );

  return swr;
};

// Alerts
export const useAlerts = () => {
  const { token } = useUser();
  const swr = useSWR(!token ? null : ['alerts', token], async ([_, token]) =>
    axios
      .get<{ data: { alerts: any[] } }>('/v1/alert', {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      })
      .then((response) => response.data.data.alerts),
  );
  return swr;
};

export const useViewAlert = (alertId: string | undefined) => {
  const alertIdRef = useRef(alertId);
  useEffect(
    function updateWebhookIdRef() {
      alertIdRef.current = alertId;
    },
    [alertId],
  );

  const { token } = useUser();
  const url = `v1/alert/${alertId}/view`;
  const swr = useSWRMutation(
    !token || alertId === undefined ? null : ['alert', token],
    ([_, token]) =>
      axios.post(
        url,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        },
      ),
    {
      revalidate: false,
    },
  );

  return swr;
};

// Payments
export const usePayments = () => {
  const { token } = useUser();
  const swr = useSWR(!token ? null : ['payments', token], async ([_, token]) =>
    axios
      .get<{ data: { payments: Payment[] } }>('/v1/payment', {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      })
      .then((response) => response.data.data.payments),
  );
  return swr;
};

export const usePaymentDetail = (id: string) => {
  const { token } = useUser();
  const swr = useSWR(!token ? null : 'paymentDetails', async () => {
    const url = `/v1/payment/${id}`;
    const { data } = await axios.get<{ data: { payment: Payment } }>(url, {
      headers: {
        authorization: `Bearer ${token}`,
        version: SphereApiVersion.ONE,
      },
    });
    return data;
  });
  return swr;
};

// Products
export const useProducts = (isUsingAppDirectory = false) => {
  const { token } = useUser();
  const swr = useSWR(!token ? null : ['products', token], async ([_, token]) =>
    axios
      .get<{ data: { products: Product[] } }>('/v1/product', {
        params: {
          limit: 200,
        },
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      })
      .then((response) => {
        const products = response.data.data.products;
        // Augment the price objects with the product object
        const augmentedProducts: Product[] = products.map((product) => ({
          ...product,
          prices: product.prices.map((price) => ({
            ...price,
            product: {
              ...product,
              prices: [],
            },
          })),
        }));

        return augmentedProducts;
      }),
  );
  return swr;
};

export const useProduct = (productId: string | null) => {
  const { token } = useUser();
  const swr = useSWR(
    !token || !productId ? null : ['product', productId, token],
    async ([_, productId, token]) =>
      axios
        .get<{ data: { product: Product } }>(`/v1/product/${productId}`, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => {
          const product = response.data.data.product;
          return product;
        }),
  );
  return swr;
};

export const useUpdateProduct = (productId: string | undefined) => {
  const productIdRef = useRef(productId);
  useEffect(
    function updateProductIdRef() {
      productIdRef.current = productId;
    },
    [productId],
  );

  const { token } = useUser();
  const swr = useSWRMutation<
    Product[],
    any,
    string[] | null,
    Pick<Product, 'name' | 'description' | 'images'> & {
      prices: string[];
    }
  >(
    !token || productId === undefined ? null : ['products', token],
    (
      [_, token],
      {
        arg: { name, description, images, prices },
      }: {
        arg: {
          name: string;
          description: string;
          images: string[];
          prices: string[];
        };
      },
    ) =>
      axios
        .post<{ data: { product: Product } }>(
          `/v1/product/${productId}`,
          {
            name,
            description,
            images,
            prices,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              version: SphereApiVersion.ONE,
            },
          },
        )
        .then((response) => response.data.data.product) as any, // force repopulating of cache by appending new product to current data
    {
      populateCache: ((result: Product, currentData: Product[]) => {
        const augmentedProduct: Product = {
          ...result,
          prices: result.prices.map((price) => ({
            ...price,
            product: {
              ...result,
              prices: [],
            },
          })),
        };

        if (currentData) {
          return currentData.map((product) =>
            product.id === productIdRef.current ? augmentedProduct : product,
          );
        }
        return [result];
      }) as any,
      revalidate: false,
    },
  );
  return swr;
};

export const useDeleteProduct = (
  productId: string | undefined,
  isUsingAppDirectory = true,
) => {
  const productIdRef = useRef(productId);
  useEffect(
    function updateProductIdRef() {
      productIdRef.current = productId;
    },
    [productId],
  );

  const { token } = useUser();
  const swr = useSWRMutation(
    !token || productId === undefined ? null : ['products', token],
    ([_, token]) =>
      axios.delete(`/v1/product/${productId}`, {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      }),
    {
      optimisticData: ((currentData: Product[]) => {
        return currentData.filter(
          (product) => product.id !== productIdRef.current,
        );
      }) as any,
      revalidate: false,
    },
  );

  return swr;
};

// Gross volume

// TODO: move to common module
export enum DatePart {
  MINUTE = 'minute',
  HOUR = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export interface Statistic {
  value: number;
  timestamp: string;
  prevValue?: number;
  prevTimestamp?: string;
}

// Payment Links
export const usePaymentLink = (
  id: string | null,
  isUsingAppDirectory?: boolean,
) => {
  const { token } = useUser();

  return useSWR(
    !token || !id ? null : ['paymentLinkDetails', token, id],
    async ([_, token]) =>
      axios
        .get<{ data: { paymentLink: PaymentLink } }>(`/v1/paymentLink/${id}`, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.paymentLink),
  );
};

export const usePaymentLinks = (shouldLoad = true) => {
  const { token } = useUser();
  const swr = useSWR(
    !token || !shouldLoad ? null : ['paymentLinks', token],
    async ([_, token]) =>
      axios
        .get<{ data: { paymentLinks: PaymentLink[] } }>('/v1/paymentLink', {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.paymentLinks),
  );
  return swr;
};
export const useDeletePaymentLink = (paymentLinkId: string | undefined) => {
  const paymentLinkIdRef = useRef(paymentLinkId);
  useEffect(
    function updatePaymentLinkIdRef() {
      paymentLinkIdRef.current = paymentLinkId;
    },
    [paymentLinkId],
  );

  const { token } = useUser();
  const swr = useSWRMutation(
    !token || paymentLinkId === undefined ? null : ['paymentLinks', token],
    ([_, token]) =>
      axios.delete(`/v1/paymentLink/${paymentLinkId}`, {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      }),
    {
      optimisticData: ((currentData: Product[]) => {
        return currentData.filter(
          (product) => product.id !== paymentLinkIdRef.current,
        );
      }) as any,
      revalidate: false,
    },
  );

  return swr;
};

export interface CreatePaymentLinkArgs {
  description: string;
  requiresEmail: boolean;
  requiresName: boolean;
  requiresShippingDetails: boolean;
  successUrl: string;
  coupon: string | null | undefined;
  lineItems: {
    id?: string;
    price: string;
    quantity: string;
    quantityMutable: boolean;
    quantityLabel: string;
  }[];
  wallets:
    | {
        id: string;
        shareBps: number;
      }[]
    | undefined;
}

/**
 * @deprecated creation of payment links does not need optimistic rendering
 */
export const useCreatePaymentLink = () => {
  const { token } = useUser();
  const swr = useSWRMutation<
    PaymentLink[],
    any,
    string[] | null,
    CreatePaymentLinkArgs
  >(
    !token ? null : ['paymentLinks', token],
    (
      [_, token],
      {
        arg: {
          description,
          requiresEmail,
          requiresName,
          requiresShippingDetails,
          lineItems,
          successUrl,
          coupon,
          wallets,
        },
      }: {
        arg: CreatePaymentLinkArgs;
      },
    ) =>
      axios
        .post<{ data: { paymentLink: PaymentLink } }>(
          '/v1/paymentLink',
          {
            description,
            requiresEmail,
            requiresName,
            requiresShippingDetails,
            lineItems,
            coupon,
            wallets,
            successUrl: successUrl.trim().length === 0 ? '' : successUrl,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              version: SphereApiVersion.ONE,
            },
          },
        )
        .then((response) => response.data.data.paymentLink) as any, // force repopulating of cache by appending new payment link to current data
    {
      populateCache: ((result: PaymentLink, currentData: PaymentLink[]) => {
        if (currentData) {
          return [...currentData, result];
        }
        return [result];
      }) as any,
      revalidate: false,
    },
  );
  return swr;
};

const paymentLinkVolumeFetcher = async ([baseUrl, paymentLinkId, token]: [
  string,
  string,
  string,
]) => {
  return baseAxios
    .get(`${baseUrl}/${paymentLinkId}/volume`, {
      headers: {
        authorization: `Bearer ${token}`,
        version: SphereApiVersion.ONE,
      },
    })
    .then(
      (response) =>
        response.data.data as {
          paymentLinkVolumeUsd: number;
          paymentLinkPaymentsCount: number;
        },
    );
};

export const usePaymentLinkVolume = (paymentLinkId: string | undefined) => {
  const { token } = useUser();

  return useSWR(
    !token || !paymentLinkId
      ? null
      : ['/v1/payment/frontend/paymentLink', paymentLinkId, token],
    paymentLinkVolumeFetcher,
  );
};

// Application
export const useDefaultApplication = () => {
  const { token } = useUser();
  const swr = useSWR(
    ['/v1/application/default' satisfies SphereApiEndpoints, token],
    ([path, token]) =>
      baseAxios
        .get<{ data: { application: Application } }>(path, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.application),
  );

  return swr;
};

// Webhooks
export const useWebhooks = (isUsingAppDirectory?: boolean) => {
  const { token } = useUser();
  const swr = useSWR(!token ? null : ['webhooks', token], async ([_, token]) =>
    axios
      .get<{ data: { webhooks: Webhook[] } }>('/v1/webhook', {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      })
      .then((response) => response.data.data.webhooks),
  );
  return swr;
};

export const useWebhook = (
  id: string | undefined,
  isUsingAppDirectory?: boolean,
) => {
  const { token } = useUser();
  const swr = useSWR(
    !token || !id ? null : ['webhooks', id, token],
    async ([_, id, token]) =>
      axios
        .get<{ data: { webhook: Webhook } }>(`/v1/webhook/${id}`, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.webhook),
  );
  return swr;
};

interface CreateWebhookParams {
  description: string;
  events: string[];
  url: string;
}

export const useCreateWebhook = (isUsingAppDirectory?: boolean) => {
  const { token } = useUser();
  const swr = useSWRMutation<
    ApiKey[],
    any,
    string[] | null,
    CreateWebhookParams
  >(
    !token ? null : ['webhooks', token],
    (
      [_, token],
      {
        arg: { description, events, url },
      }: {
        arg: CreateWebhookParams;
      },
    ) =>
      axios
        .post<{ data: { webhook: Webhook } }>(
          '/v1/webhook',
          {
            description,
            events,
            url,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              version: SphereApiVersion.ONE,
            },
          },
        )
        .then((response) => response.data.data.webhook) as any, // force repopulating of cache by appending new product to current data
    {
      populateCache: ((result: Webhook, currentData: Webhook[]) => {
        if (currentData) {
          return [...currentData, result];
        }
        return [result];
      }) as any,
      revalidate: false,
    },
  );
  return swr;
};

export const useDeleteWebhook = (webhookId: string | undefined) => {
  const webhookIdRef = useRef(webhookId);
  useEffect(
    function updateWebhookIdRef() {
      webhookIdRef.current = webhookId;
    },
    [webhookId],
  );

  const { token } = useUser();
  const swr = useSWRMutation(
    !token || webhookId === undefined ? null : ['webhooks', token],
    ([_, token]) =>
      axios.delete(`/v1/webhook/${webhookId}`, {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      }),
    {
      revalidate: false,
    },
  );

  return swr;
};

export const useUpdateWebhook = (
  webhookId: string | undefined,
  isUsingAppDirectory?: boolean,
) => {
  const webhookIdRef = useRef(webhookId);
  useEffect(
    function updateWebhookIdRef() {
      webhookIdRef.current = webhookId;
    },
    [webhookId],
  );

  const { token } = useUser();
  const swr = useSWRMutation<
    Webhook[],
    any,
    string[] | null,
    Partial<CreateWebhookParams>
  >(
    !token || webhookId === undefined ? null : ['webhooks', token],
    (
      [_, token],
      {
        arg,
      }: {
        arg: Partial<CreateWebhookParams>;
      },
    ) =>
      axios
        .post<{ data: { webhook: Webhook } }>(`/v1/webhook/${webhookId}`, arg, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.webhook) as any, // force repopulating of cache by appending new product to current data
    {
      populateCache: ((result: Webhook, currentData: Webhook[]) => {
        if (currentData) {
          return currentData.map((webhook) =>
            webhook.id === webhookIdRef.current ? result : webhook,
          );
        }
        return [result];
      }) as any,
      revalidate: false,
    },
  );
  return swr;
};

export const USE_REQUESTS_LIMIT = 20;
export const useRequests = (
  {
    endDate,
  }: {
    endDate: string | undefined;
  },
  isUsingAppDirectory?: boolean,
) => {
  const { token } = useUser();
  const swr = useSWR(
    !token ? null : ['/v1/request', USE_REQUESTS_LIMIT, endDate],
    async ([route, limit, endDate]) =>
      axios
        .get<{ data: { requests: Request[] } }>(
          `${route}?${qs.stringify({
            limit,
            ...(endDate !== undefined ? { endDate } : {}),
          })}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              version: SphereApiVersion.ONE,
            },
          },
        )
        .then((response) => response.data.data.requests),
  );
  return swr;
};

export const useRequest = (requestId: string | undefined) => {
  const { token } = useUser();
  const swr = useSWR(
    !token ? null : ['useRequest', requestId],
    async ([_, requestId]) =>
      axios
        .get<{ data: { request: Request } }>(`/v1/request/${requestId}`, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.request),
  );

  return swr;
};

export const useEgressRequests = (
  {
    webhookId,
    endDate,
  }: {
    webhookId: string | undefined;
    endDate: string | undefined;
  },
  isUsingAppDirectory?: boolean,
) => {
  const { token } = useUser();
  const swr = useSWR(
    !token || webhookId === undefined
      ? null
      : ['/v1/request', 'egress', USE_REQUESTS_LIMIT, webhookId, endDate],
    async ([route, requestType, limit, webhookId, endDate]) =>
      axios
        .get<{ data: { requests: Request[] } }>(
          `${route}?${qs.stringify({
            limit,
            requestType,
            webhookId,
            ...(endDate !== undefined ? { endDate } : {}),
          })}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              version: SphereApiVersion.ONE,
            },
          },
        )
        .then((response) => response.data.data.requests),
  );
  return swr;
};

// Api Keys
export const useApiKeys = () => {
  const { token } = useUser();
  const swr = useSWR(!token ? null : ['apiKeys', token], async ([_, token]) =>
    axios
      .get<{ data: { apiKeys: ApiKey[] } }>('/v1/apiKey', {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      })
      .then((response) => response.data.data.apiKeys),
  );
  return swr;
};

interface CreateApiKeyParams {
  name: string;
  note: string;
}

export const useCreateApiKey = () => {
  const { token } = useUser();
  const swr = useSWRMutation<
    ApiKey[],
    any,
    string[] | null,
    CreateApiKeyParams
  >(
    !token ? null : ['apiKeys', token],
    (
      [_, token],
      {
        arg,
      }: {
        arg: CreateApiKeyParams;
      },
    ) =>
      axios
        .post<{ data: { apiKey: ApiKey } }>('/v1/apiKey', arg, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => [response.data.data?.apiKey]) as any, // force repopulating of cache by appending new product to current data
    {
      populateCache: ((result: { apiKey: ApiKey }, currentData: ApiKey[]) => {
        if (currentData) {
          return [
            {
              ...result.apiKey,
              plainTextApiKey: result.apiKey?.plainTextApiKey,
            },
            ...currentData,
          ];
        }
        return [
          {
            ...result.apiKey,
            plainTextApiKey: result.apiKey?.plainTextApiKey,
          },
        ];
      }) as any,
      revalidate: false,
    },
  );
  return swr;
};

export const useDeleteApiKey = (
  apiKeyId: string | undefined,
  isUsingAppDirectory?: boolean,
) => {
  const apiKeyIdRef = useRef(apiKeyId);
  useEffect(
    function updateWebhookIdRef() {
      apiKeyIdRef.current = apiKeyId;
    },
    [apiKeyId],
  );

  const { token } = useUser();
  const swr = useSWRMutation(
    !token || apiKeyId === undefined ? null : ['apiKeys', token],
    ([_, token]) =>
      axios.delete(`/v1/apiKey/${apiKeyId}`, {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      }),
    {
      revalidate: false,
    },
  );

  return swr;
};

export const useUpdateApiKey = (apiKeyId: string | undefined) => {
  const apiKeyIdRef = useRef(apiKeyId);
  useEffect(
    function updateWebhookIdRef() {
      apiKeyIdRef.current = apiKeyId;
    },
    [apiKeyId],
  );

  const { token } = useUser();
  const swr = useSWRMutation<
    ApiKey[],
    any,
    string[] | null,
    CreateApiKeyParams
  >(
    !token || apiKeyId === undefined ? null : ['apiKeys', token],
    (
      [_, token],
      {
        arg,
      }: {
        arg: CreateApiKeyParams;
      },
    ) =>
      axios
        .post<{ data: { apiKey: ApiKey } }>(`/v1/apiKey/${apiKeyId}`, arg, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.apiKey) as any, // force repopulating of cache by appending new product to current data
    {
      populateCache: ((result: ApiKey, currentData: ApiKey[]) => {
        if (currentData) {
          return currentData.map((apiKey) =>
            apiKey.id === apiKeyIdRef.current ? result : apiKey,
          );
        }
        return [result];
      }) as any,
      revalidate: false,
    },
  );
  return swr;
};

// Wallets
export const useMerchantOwnedVerifiedWallets = () => {
  const { token } = useUser();
  const swr = useSWR(!token ? null : ['wallet', token], async ([_, token]) =>
    axios
      .get<{ data: { wallets: Wallet[] } }>('/v1/wallet', {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
        params: {
          limit: 200,
          isOwnershipVerified: true,
        },
      })
      .then((response) => response.data.data.wallets),
  );

  return swr;
};

export const useCustodialWallets = () => {
  const { token } = useUser();
  const { data } = useApplicationCustomer();
  const applicationCustomerId = data?.id;

  const swr = useSWR(
    !token ? null : ['custodialWallet', token, applicationCustomerId],
    async ([_, token, applicationCustomerId]) => {
      const response = await axios.get<{ data: { wallets: Wallet[] } }>(
        '/v1/wallet/custodial/list',
        {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.TWO,
          },
        },
      );

      return response.data.data.wallets;
    },
  );

  return swr;
};

export const useApplicationCustomerWallets = () => {
  const { token } = useUser();
  const { data } = useApplicationCustomer();
  const applicationCustomerId = data?.id;

  const swr = useSWR(
    !token
      ? null
      : ['applicationCustomerWallets', token, applicationCustomerId],
    async ([_, token, applicationCustomerId]) => {
      const response = await axios.get<{ data: { wallets: Wallet[] } }>(
        '/v1/wallet',
        {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
          params: {
            customer: applicationCustomerId,
            limit: 200,
          },
        },
      );

      return response.data.data.wallets;
    },
  );

  return swr;
};

// Events
export const useEvents = ({
  startDate,
  endDate,
  limit,
}: {
  startDate?: string;
  endDate?: string;
  limit: number;
}) => {
  const { token } = useUser();
  const swr = useSWR(
    !token ? null : ['/v1/event', startDate, endDate, limit],
    async ([route, startDate, endDate, limit]) =>
      axios
        .get<{ data: { events: Event[] } }>(
          `${route}?${qs.stringify({
            limit,
            ...(startDate === undefined ? {} : { startDate }),
            ...(endDate === undefined ? {} : { endDate }),
          })}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              version: SphereApiVersion.ONE,
            },
          },
        )
        .then((response) => response.data.data.events),
  );
  return swr;
};

export const useEvent = (
  id: string | undefined,
  isUsingAppDirectory?: boolean,
) => {
  const { token } = useUser();
  const swr = useSWR(
    !token || !id ? null : ['event', id, token],
    async ([_, id, token]) =>
      axios
        .get<{ data: { event: Event } }>(`/v1/event/${id}`, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.event),
  );
  return swr;
};

// TODO: move tokens to common module
export const TOKENS_ROUTE_MAP_KEY = 'tokensRouteMap';
export const useTokensRouteMap = () => {
  const swr = useSWRImmutable<TokensRouteMap[]>(
    TOKENS_ROUTE_MAP_KEY,
    () => axios('/v1/supportedToken').then((response) => response.data),
    {
      shouldRetryOnError: true,
    },
  );
  return swr;
};

export const useTokenInfo = (
  tokenAddress: string | undefined,
  network: Network = Network.SOL,
) => {
  const { data: tokensRouteMap } = useTokensRouteMap();
  const tokenRouteMap = useMemo(
    () =>
      tokensRouteMap?.find(
        (token) => token.inputToken.address === tokenAddress,
      ),
    [tokenAddress, tokensRouteMap],
  );

  const evmTokenInfo = useEvmTokenInfo(tokenAddress, network);
  const btcTokenInfo = useBtcTokenInfo(tokenAddress);

  return network === Network.SOL
    ? tokenRouteMap?.inputToken
    : network === Network.BITCOIN
      ? btcTokenInfo
      : evmTokenInfo;
};

export const useTokensInfos = () => {
  const { data: tokensRouteMap } = useTokensRouteMap();

  const tokensInfos = useMemo(
    () => tokensRouteMap?.map((token) => token.inputToken),
    [tokensRouteMap],
  );

  const evmTokens = useEvmTokens();
  const btcTokens = useBtcTokens();

  if (!tokensInfos || !evmTokens.data || !btcTokens.data) {
    return undefined;
  }

  return [
    ...tokensInfos,
    ...btcTokens.data,
    ...evmTokens.data.flatMap(({ tokens }) => tokens),
  ];
};

export const usePossibleInputTokens = (outputToken?: Token) => {
  const { data: tokensRouteMap } = useTokensRouteMap();
  const { data } = useSWR(
    !tokensRouteMap
      ? null
      : ['possibleInputTokens', tokensRouteMap, outputToken],
    ([_, tokensRouteMap, outputToken]) =>
      tokensRouteMap
        .reduce<Token[]>((acc, curr) => {
          const isPossibleInput = !outputToken
            ? true
            : curr.inputToken.address === outputToken.address ||
              curr.outputTokens.some(
                (token) => token.address === outputToken.address,
              );
          if (isPossibleInput) {
            acc.push(curr.inputToken);
          }
          return acc;
        }, [])
        .sort((a, b) => a.name.localeCompare(b.name)),
  );

  return data;
};

const POPULAR_TOKENS = {
  EURC: 'HzwqbKZw8HxMN6bF2yFZNrht3c2iXXzpKcFu7uBEDKtr',
  SOL: 'So11111111111111111111111111111111111111112',
  USDC: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  USDT: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
  mSOL: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
  stSOL: '7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj',
  ETH: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
};

export interface CreatePromotionCodeArgs {
  coupon: string;
  code?: string;
  active?: boolean;
  customer?: string;
  expiresAt?: string;
  maxRedemptions?: number;
}

export const useDefaultTokensShown = ({
  outputToken,
  selectedToken,
}: {
  outputToken?: Token;
  selectedToken: Token | null;
}) => {
  const { data: tokensRouteMap } = useTokensRouteMap();
  const { data } = useSWR(
    !tokensRouteMap
      ? null
      : ['defaultTokensShown', tokensRouteMap, outputToken, selectedToken],
    ([_, tokensRouteMap, outputToken, selectedToken]) => {
      const popularTokensRouteMap = Object.values(POPULAR_TOKENS).map(
        (popularTokenAddress) =>
          tokensRouteMap.find(
            (tokenRouteMap) =>
              tokenRouteMap.inputToken.address === popularTokenAddress,
          ),
      );

      const isContainsUndefined = popularTokensRouteMap.some(
        (routeMap) => routeMap === undefined,
      );
      if (isContainsUndefined) {
        return undefined;
      }

      const possiblePopularTokens = (popularTokensRouteMap as TokensRouteMap[])
        .filter((routeMap) => {
          if (outputToken === undefined) {
            return true;
          }
          return (
            routeMap.inputToken.address === outputToken.address ||
            routeMap.outputTokens.some(
              (token) => token.address === outputToken.address,
            )
          );
        })
        .map((routeMap) => routeMap.inputToken);

      if (
        outputToken !== undefined &&
        !Object.values(POPULAR_TOKENS).includes(outputToken.address)
      ) {
        possiblePopularTokens.unshift(outputToken);
      }

      if (
        selectedToken !== null &&
        !Object.values(POPULAR_TOKENS).includes(selectedToken.address) &&
        outputToken?.address !== selectedToken.address
      ) {
        possiblePopularTokens.unshift(selectedToken);
      }

      return possiblePopularTokens;
    },
  );

  return data;
};

/**
 * @deprecated use hook from @spherelabs/react-private instead
 */
export const useJupiterQuote = (params?: FetchQuotesParams) => {
  const debouncedParams = useDebounce(params);
  const swr = useSWR(
    ['jupiterQuote', debouncedParams],
    !debouncedParams || debouncedParams.inputMint === debouncedParams.outputMint
      ? null
      : () =>
          axios
            .get<{
              data: JupiterRoute[];
            }>(
              `https://quote-api.jup.ag/v4/quote?inputMint=${
                debouncedParams.inputMint
              }&outputMint=${debouncedParams.outputMint}&amount=${
                debouncedParams.rawAmount
              }&slippageBps=${debouncedParams.slippageBps ?? 50}&swapMode=${
                debouncedParams.swapMode ?? 'ExactOut'
              }`,
            )
            .then((response) => response.data.data),
    {
      refreshInterval: 20000,
    },
  );

  return swr;
};

// EVM tokens
export const useEvmTokens = () => {
  const swr = useSWRImmutable(
    '/v1/supportedToken/evm',
    (key) =>
      axios
        .get<Array<{ name: string; tokens: Token[] }>>(key)
        .then((response) => response.data),
    {
      shouldRetryOnError: true,
    },
  );

  return swr;
};

// BTC tokens
export const useBtcTokens = () => {
  const swr = useSWRImmutable(
    '/v1/supportedToken/bitcoin',
    (key) => axios.get<Token[]>(key).then((response) => response.data),
    {
      shouldRetryOnError: true,
    },
  );

  return swr;
};

export const useTokensForEvmNetwork = (network: Network) => {
  const { data } = useEvmTokens();

  const tokens = useMemo(
    () =>
      !data || !isEvm(network)
        ? undefined
        : data.find((chain) => chain.name === network.toString())?.tokens,
    [data, network],
  );

  return tokens;
};

export const useEvmTokenInfo = (
  tokenAddress: string | undefined,
  network: Network,
) => {
  const tokens = useTokensForEvmNetwork(network);

  const tokenInfo = useMemo(
    () => tokens?.find((token) => token.address === tokenAddress),
    [tokenAddress, tokens],
  );

  return tokenInfo;
};

export const useBtcTokenInfo = (tokenAddress: string | undefined) => {
  const { data: tokens } = useBtcTokens();

  const tokenInfo = useMemo(
    () => tokens?.find((token) => token.address === tokenAddress),
    [tokenAddress, tokens],
  );

  return tokenInfo;
};

// Membership
export const useDeleteMember = (membershipId: string | undefined) => {
  const membershipIdRef = useRef(membershipId);
  useEffect(
    function updateMemershipIdRef() {
      membershipIdRef.current = membershipId;
    },
    [membershipId],
  );

  const { token } = useUser();
  const swr = useSWRMutation(
    !token || !membershipId ? null : ['DELETE_MEMBERSHIP', token, membershipId],
    ([_, token]) =>
      axios.delete(`/v1/membership/${membershipId}`, {
        headers: {
          authorization: `Bearer ${token}`,
          version: SphereApiVersion.ONE,
        },
      }),
    {
      revalidate: false,
    },
  );

  return swr;
};

export const useResendWebhookEvent = (
  requestId: string | undefined,
  isUsingAppDirectory = true,
) => {
  const { token } = useUser();
  const swr = useSWRMutation([requestId], async ([requestId]) =>
    axios
      .post<{ data: { webhookRecord: WebhookRecord } }>(
        `/v1/webhook/${requestId}/resend`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        },
      )
      .then((response) => response.data.data.webhookRecord),
  );
  return swr;
};

export const usePaymentEvent = (
  {
    paymentId,
  }: {
    paymentId: string;
  },
  isUsingAppDirectory?: boolean,
) => {
  const { token } = useUser();
  return useSWR(
    !token ? null : ['/v1/event/payment', paymentId],
    async ([route, paymentId]) =>
      axios
        .get<{ data: { events: Event[] } }>(`${route}/${paymentId}`, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.events),
  );
};

export const useSubscriptionEvent = (
  {
    subscriptionId,
  }: {
    subscriptionId: string;
  },
  isUsingAppDirectory?: boolean,
) => {
  const { token } = useUser();
  return useSWR(
    !token ? null : ['/v1/event/subscription', subscriptionId],
    async ([route, subscriptionId]) =>
      axios
        .get<{ data: { events: Event[] } }>(`${route}/${subscriptionId}`, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.events),
    {
      refreshInterval: 2500, // TODO: remove this once the components are refactored
    },
  );
};

// Customer hooks
export const useCustomerSubscriptions = () => {
  const token = useCustomerToken({ isProtectedRoute: false });
  return useSWR(
    !token ? null : ['/v1/public/customer/subscriptions', token],
    async ([route, token]) =>
      axios
        .get<{
          data: {
            subscriptions: (Subscription & { application: any })[];
          };
        }>(route, {
          headers: {
            authorization: `Bearer ${token}`,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => {
          return response.data.data.subscriptions;
        }),
  );
};

export const useCustomerSubscription = ({
  subscriptionId,
}: {
  subscriptionId: string;
}) => {
  const token = useCustomerToken({ isProtectedRoute: false });

  return useSWR(
    [
      token
        ? '/v1/public/customer/subscriptions/auth'
        : '/v1/public/customer/subscriptions',
      subscriptionId,
      token,
    ],
    async ([route, subscriptionId, token]) =>
      axios
        .get<{
          data: {
            subscription: Subscription;
            application: Application;
          };
        }>(`${route}/${subscriptionId}`, {
          headers: !token
            ? undefined
            : {
                authorization: `Bearer ${token}`,
                version: SphereApiVersion.ONE,
              },
        })
        .then((response) => {
          const data = response.data.data;
          return {
            subscription: data.subscription,
            application: data.application,
          };
        }),
  );
};

export const useNetworks = () => {
  const tokens = useTokensInfos();
  const networks = useMemo(
    () =>
      tokens
        ? Array.from(new Set(tokens.map((token) => token.network)))
        : undefined,
    [tokens],
  );

  if (!networks) {
    return undefined;
  }

  const localhostRemoved = networks.filter(
    (network) => network !== Network.LOCALHOST,
  );

  return localhostRemoved;
};

// Application Statistics
export const useDefaultApplicationStatistics = () => {
  const { token } = useUser();
  const swr = useSWR(
    !token ? null : ['defaultApplicationStatistics', token],
    async ([, ,]) =>
      axios
        .get<{ data: { applicationStatistics: ApplicationStatistics } }>(
          '/v1/application/default/statistics',
          {
            headers: {
              authorization: `Bearer ${token}`,
              version: SphereApiVersion.ONE,
            },
          },
        )
        .then((response) => {
          const applicationStatistics =
            response.data.data.applicationStatistics;
          return applicationStatistics;
        }),
    {
      shouldRetryOnError: (error) => {
        return error?.response?.status !== 404;
      },
    },
  );

  return swr;
};

const baseAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8080',
});

//Payouts
export const useCreatePayout = () => {
  const { token } = useUser();

  const swr = useSWRMutation(
    !token ? null : ['v1/transfers', token],

    async (payoutData) => {
      const response = await baseAxios.post('/v1/transfer', payoutData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    },
    {
      populateCache: (result, currentData) => {
        return currentData ? [...currentData, result] : [result];
      },
      revalidate: false,
    },
  );

  return swr;
};

// bank accounts
export const useCustomerBankAccounts = (
  customerId: string | undefined,
  mock = false,
) => {
  const { token } = useUser();

  const swr = useSWR(
    !token || !customerId ? null : ['bankAccounts', customerId, token, mock],
    async ([_, customerId, token, mock]) => {
      if (!customerId) return undefined;
      return baseAxios
        .get<{ data: { bankAccounts: BankAccountV2[] } }>('/v1/bankAccount', {
          headers: {
            authorization: `Bearer ${token}`,
          },
          params: {
            mock,
            customer: customerId,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.bankAccounts);
    },
  );

  return swr;
};

export const useBankAccounts = (mock = false) => {
  const { token } = useUser();

  return useSWR(
    !token ? null : ['bankAccounts', token, mock],
    async ([_, token, mock]) =>
      baseAxios
        .get<{ data: { bankAccounts: BankAccountV2[] } }>('/v1/bankAccount', {
          headers: {
            authorization: `Bearer ${token}`,
          },
          params: {
            mock,
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data.bankAccounts),
  );
};

export const usePayoutStatsForMerchantCustomersOvertime = (
  dateRange: [startDate: Date, endDate: Date],
) => {
  const { token } = useUser();

  return useSWR(
    !token ? null : ['/v1/transfer/stats/overtime', token, ...dateRange],
    async ([url, token, startDate, endDate]) =>
      baseAxios
        .get<{
          data: PayoutStatsOvertimeV2;
        }>(url, {
          params: {
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => response.data.data),
  );
};

export const useCards = (mock = false) => {
  const { token } = useUser();

  return useSWR(
    /**!token**/ // biome-ignore lint/correctness/noConstantCondition: temporarily disable this api cal as push to card doesn't work for now
    true ? null : ['cards', token, mock],
    async ([_, token, mock]) =>
      baseAxios
        .get<{ data: { cards: AstraFiCard[] } }>('/v1/card', {
          headers: {
            authorization: `Bearer ${token}`,
          },
          params: {
            mock,
          },
        })
        .then((response) => response.data.data.cards),
  );
};

export const useCustomersFrontend = ({
  mock = false,
  query,
}: {
  mock?: boolean;
  query?: Omit<
    RequestParams<SphereApiSchema, '/v1/customer/frontend', 'get'>['query'],
    'mock'
  >;
}) => {
  const sphereApi = useSphereApi()['/v1/customer/frontend'];
  const fetchApplicationCustomers = sphereApi.get;
  const searchParams = useSearchParams();

  const pageParam = searchParams.get('page');
  const page =
    pageParam && !Number.isNaN(Number(pageParam)) ? Number(pageParam) : 1;

  const limitParam = searchParams.get('limit');
  const limit =
    limitParam && !Number.isNaN(Number(limitParam))
      ? Number(limitParam)
      : DEFAULT_EVENTS_PER_QUERY;

  return useSWR(
    { path: '/v1/customer/frontend', limit, page, query, mock },
    async ({ limit, page, query, mock }) => {
      const response = await fetchApplicationCustomers({
        query: { limit, page, ...query, mock },
      });
      const parsedResponse = await response.json();
      const customers = parsedResponse.data.customers;

      return customers;
    },
  );
};

const customerPayoutsVolumeFetcher = async ([
  baseUrl,
  customerId,
  token,
  mock,
]: [string, string, string, boolean]) => {
  return baseAxios
    .get(`${baseUrl}/${customerId}/payoutsVolume`, {
      headers: {
        authorization: `Bearer ${token}`,
        version: SphereApiVersion.ONE,
      },
      params: {
        mock,
        status: 'succeeded',
      },
    })
    .then(
      (response) =>
        response.data.data as {
          customerPayoutsVolumeUsd: number;
          customerPayoutsThirtyDayVolumeUsd: number;
        },
    );
};

export const useCustomerPayoutsVolume = (
  customerId: string | undefined,
  mock = false,
) => {
  const { token } = useUser();

  return useSWR(
    !token || !customerId
      ? null
      : ['/v1/payout/frontend/customer', customerId, token, mock],
    customerPayoutsVolumeFetcher,
  );
};

const useApplicationCustomerPayoutsStatisticsFetcher = async ([url, token]: [
  string,
  string,
]) => {
  return baseAxios
    .get(url, {
      headers: {
        authorization: `Bearer ${token}`,
        version: SphereApiVersion.ONE,
      },
    })
    .then(
      (response) =>
        response.data.data as {
          onRamp: {
            payoutsVolumeUsd: number;
            payoutsSucceededCount: number;
            payoutsTotalCount: number;
          };
          offRamp: {
            payoutsVolumeUsd: number;
            payoutsSucceededCount: number;
            payoutsTotalCount: number;
          };
          total: {
            payoutsVolumeUsd: number;
            payoutsSucceededCount: number;
            payoutsTotalCount: number;
          };
        },
    );
};

export const useApplicationCustomerPayoutsAnalytics = () => {
  const { token } = useUser();

  return useSWR(
    !token
      ? null
      : ['/v1/payout/frontend/customer/application/payoutsStatistics', token],
    useApplicationCustomerPayoutsStatisticsFetcher,
  );
};

export const usePayoutAdjustments = (data: {
  source: {
    type: 'bankAccount' | 'wallet';
    id: string | undefined;
    currency: PayoutCurrency;
    network: PayoutNetwork;
  };
  destination: {
    type: 'bankAccount' | 'wallet';
    id: string | undefined;
    currency: PayoutCurrency;
    network: PayoutNetwork;
  };
  calculationTarget: 'source' | 'destination';
  customer: string | undefined;
}) => {
  const { token } = useUser();
  const [amountAdjustments, setAmountAdjustments] = useState<PayoutAmount[]>();
  const latestResponseInitiatedTimestamp = useRef(0);

  const fetchNewAmountAdjustments = useCallback(
    async (amount: number) => {
      if (!data.customer) return;
      const payload: SetNonNullable<GetPayoutAmountAdjustmentsDTO> = {
        amount: amount.toString(),
        customerId: data.customer,
        destinationCurrency: data.destination.currency,
        destinationNetwork: data.destination.network,
        sourceCurrency: data.source.currency,
        received: data.calculationTarget === 'source',
        provider: PayoutOnOffRampProvider.Bridge,
      };

      const initiatedTimestamp = Date.now();
      type GetPayoutAmountAdjustmentsResponse = SphereApiResponseDTO<{
        amountAdjustments: PayoutAmount[];
      }>;

      // TODO This data will need to change preferably to GetPayoutAmountAdjustmentsDTO or at least include application/customer
      const response = await baseAxios.post<
        GetPayoutAmountAdjustmentsResponse,
        AxiosResponse<GetPayoutAmountAdjustmentsResponse>,
        SetNonNullable<GetPayoutAmountAdjustmentsDTO>
      >('/v1/payout/amountAdjustments', payload, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (initiatedTimestamp < latestResponseInitiatedTimestamp.current) {
        return;
      }
      setAmountAdjustments(response.data.data.amountAdjustments);
      latestResponseInitiatedTimestamp.current = initiatedTimestamp;
    },
    [data, token],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: we only want to run this effect once on mount
  useEffect(function initializeAmountAdjustments() {
    fetchNewAmountAdjustments(0);
  }, []);

  return {
    amountAdjustments,
    fetchNewAmountAdjustments,
  };
};

export const usePayoutTimeline = (payoutId: string) => {
  const { token } = useUser();

  return useSWR(
    !token ? null : [`/v1/payout/frontend/${payoutId}/timeline`, token],
    async ([url, token]) =>
      baseAxios
        .get<{
          data: EventV2[];
        }>(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => response.data.data),
  );
};

export const useCreateAccessRequest = () => {
  return useSWRMutation(
    '/v1/accessRequest',
    async (url, { arg }: { arg: CreateAccessRequestDTO }) =>
      baseAxios
        .post<{
          data: {
            success: boolean;
            customerType?: AccessRequestCustomerType;
          };
        }>(url, arg, {
          headers: {
            version: SphereApiVersion.ONE,
          },
        })
        .then((response) => response.data.data),
    {
      revalidate: false,
    },
  );
};

export const useGetAccessRequestByEmail = (email: string) => {
  return useSWR(['/v1/accessRequest', email], async ([url, email]) =>
    baseAxios
      .get<{
        data?: {
          accessRequest?: {
            status?: AccessRequestStatus;
            application?: {
              id?: string;
              earlyAccess?: boolean;
            };
          };
        };
      }>(url, {
        params: {
          email,
        },
        headers: {
          version: SphereApiVersion.ONE,
        },
      })
      .then((response) => response.data.data?.accessRequest),
  );
};

export const useIsSelfCustodyWalletsInitialized = () => {
  const { token } = useUser();

  return useSWR(
    !token ? null : ['/v1/self-custody-wallets/status', token],
    async ([url, token]) =>
      baseAxios
        .get<boolean>(url, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => response.data),
  );
};
